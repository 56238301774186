<template>
    <div class="add-supplier">
        <div v-loading="is_pending" class="add-supplier-modal">
            <div class="add-supplier-modal-title">
                <h2>הוספת ספק</h2>
                <div @click="$emit('close')" class="exit-btn"><i class="material-icons">close</i></div>
            </div>
            <div class="add-supplier-modal-content">
                <label>
                    <span style="font-size: 20px;">מספר ספק</span>
                    <el-input type="tel" v-model="supplier.supplier_num" placeholder="מס ספק" />
                </label>
                <br><br>
                <label>
                    <span style="font-size: 20px;">שם ספק</span>
                    <el-input v-model="supplier.supplier_name" placeholder="שם ספק" />
                </label>
                <br><br>
                <el-button v-if="!edit_supplier" @click="handle_add_supply" style="width: 100%;" type="primary">הוספה</el-button>
                <el-button v-else @click="handle_edit_supply"  style="width: 100%;" type="warning">עדכון</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import {slide_pop_error,alert} from '../../../../Methods/Msgs'
import {suppliers,edit_supplier_in_db,add_supplier_in_db} from './store'

export default {
    props:['edit_supplier'],
    emits:['close','submit'],
    setup (props,{emit}) {
        
        const supplier = ref({
            supplier_name: '',
            supplier_num: ''
        })

        const is_pending = ref(false)

        const handle_add_supply = async(supply) => {
            try{
                is_pending.value = true
                if(!supplier.value.supplier_name || !supplier.value.supplier_num){
                    throw new Error('עליך להזין את 2 השדות!')
                }
                await add_supplier_in_db(supplier.value)
          
                alert('success','הצלחה',`הספק: ${supplier.value.supplier_name} הוסף בהצלחה`)
                reset()
                is_pending.value = false
            }catch(err){
                console.error(err)
                is_pending.value = false
                slide_pop_error(err.message)
            }
        } 

        const handle_edit_supply = async() => {
            try{
                is_pending.value = true
                if(!supplier.value.supplier_name || !supplier.value.supplier_num){
                    throw new Error('עליך להזין את 2 השדות!')
                }
                await edit_supplier_in_db(supplier.value)
                
                alert('success','הצלחה',`הספק: ${supplier.value.supplier_name} עודכן בהצלחה`)
                is_pending.value = false
                emit('close')
            }catch(err){
                console.error(err)
                is_pending.value = false
                slide_pop_error(err.message)
            }
        }

        const reset = () => {
            supplier.value = {
                supplier_name: '',
                supplier_num: ''
            }
        }

        if(props.edit_supplier){
            supplier.value = props.edit_supplier
        }

        return {
            handle_add_supply,
            reset,
            supplier,
            is_pending,
            handle_edit_supply,
        }
    }
}
</script>

<style scoped>
    .add-supplier{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        color: #333;

    }
    .add-supplier-modal{
        width: 100%;
        max-width: 500px;
        background: #fff;
        border-radius: 10px;
    }
    .add-supplier-modal-title{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid lightgray;
    }
    .add-supplier-modal-content{
        width: 100%;
        padding: 10px;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        background: var(--danger);
        border-radius: 50%;
        color: var(--danger);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #fff;
        user-select: none;
    }

</style>